define('slarson/components/content/tax/2018-update/faq', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    tagName: 'section',
    classNames: ['section', 'banded', 'anchor'],
    classNameBindings: ['viewportEntered:active'],
    didEnterViewport: function didEnterViewport() {
      this.get('state').set('subpage', 'tax-update-2018');
    }
  });
});