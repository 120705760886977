define('slarson/components/accordion-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['accordion-container'],
    isExpanded: false,
    classNameBindings: ['isExpanded:active'],
    click: function click() {
      this.toggleProperty('isExpanded');
      return false;
    }
  });
});