define('slarson/components/content/home/carousel-container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var timer;

  exports.default = Ember.Component.extend({
    swiperOptions: {
      //freeMode: true,
      spaceBetween: 0,
      grabCursor: true,
      pagination: true,
      parallax: true,
      autoplay: {
        delay: 15000
      },
      speed: 1000,
      hashnav: true
    },
    autoplayDelay: 15000,
    changeSlide: function changeSlide() {
      var sldSwiper = document.querySelector('.swiper-container').swiper;

      if (sldSwiper.isEnd) {
        sldSwiper.slideTo(0, 500);
      } else {
        sldSwiper.slideNext();
      }
    },
    didInsertElement: function didInsertElement() {
      timer = setInterval(this.changeSlide, this.autoplayDelay);
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(timer);
    }
  });
});