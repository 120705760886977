define('slarson/components/footer-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['footer-bar']
  });
});