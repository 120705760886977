define("slarson/routes/mortgage", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {

      Ember.$(document).ready(function ($) {
        $(".subnav a").click(function (event) {
          Ember.$(".subnav a").removeClass('active');
          Ember.$(this).addClass('active');
          event.preventDefault();
          var buffer = 110;
          var scrollToY = $(this.hash).offset().top - buffer;
          $('html,body').animate({ scrollTop: scrollToY }, 500);
        });
      });
    },

    afterModel: function afterModel(transition) {
      Ember.$('*').animate({ scrollTop: 0 }, 500);
      this._super(transition);
    }
  });
});