define('slarson/services/state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    subpage: '',
    subtab: 'strategies-2019'
  });
});