define('slarson/router', ['exports', 'slarson/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('tax', function () {
      this.route('business-owner-expense-list');
      this.route('rental-property-expense-list');
      this.route('2018-tax-reform-update', { path: 'tax-reform-update' });
      this.route('2019-tax-update');
      this.route('2020-covid19-impact');
      this.route('2020-stimulus-rebate');
    });
    this.route('real-estate');
    this.route('about');
    this.route('contact');
    this.route('mortgage');
    this.route('not-found', { path: '/*wildcard' });
    this.route('news', function () {
      this.route('2020', function () {
        this.route('stimulus-rebate');
        this.route('covid19');
        this.route('covid19-2020-impact');
        this.route('sba-assistance');
      });
    });
  });

  exports.default = Router;
});