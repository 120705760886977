define("slarson/routes/tax/2019-tax-update", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {

      Ember.$(document).ready(function ($) {
        $(".subnav a").click(function (event) {
          Ember.$(".subnav a").removeClass('active');
          Ember.$(this).addClass('active');
          event.preventDefault();
          var buffer = 110;
          var scrollToY = Ember.$(this.hash).position().top - buffer;
          $('body').animate({ scrollTop: scrollToY }, 500);
        });
      });
    },

    afterModel: function afterModel(transition) {
      this.get('state').set('subpage', 'strategies');
      Ember.$('*').animate({ scrollTop: 0 }, 500);
      this._super(transition);
    },
    actions: {
      changeTab: function changeTab(name) {
        this.get('state').set('subtab', name);
        function slideToTarget() {
          var buffer = 0;
          var scrollToY = Ember.$('#update-2019-intro').offset().top - buffer;
          Ember.$('body').animate({ scrollTop: scrollToY }, 500);
        }
        window.setTimeout(slideToTarget, 250);

        return false;
      }
    }
  });
});