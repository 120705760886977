define('slarson/initializers/link-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{

    Ember.LinkComponent.reopen({
      attributeBindings: ['aria-label']
    });
  }
});