define('slarson/models/person', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    //username: DS.attr('string'),
    //twitterhandle: DS.attr('string'),
    //facebookhandle: DS.attr('string'),
    //linkedinhandle: DS.attr('string'),
    //address:  DS.attr('string'),
    //city:  DS.attr('string'),
    //state:  DS.attr('string'),
    //zip:  DS.attr('number'),
    phone: _emberData.default.attr('string'),
    //mobile:  DS.attr('string'),
    //company:  DS.attr('string'),
    //title:  DS.attr('string'),
    message: _emberData.default.attr('string')
    //isSubscribed:  DS.attr('boolean'),
  });
});