define('slarson/routes/contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var self = undefined;
  exports.default = Ember.Route.extend({
    isSubmitted: false,
    isMailer: false,
    isError: false,
    errorReason: '',
    model: function model() {
      self = this;
      var Prospect = this.store.createRecord('person', {
        firstname: '',
        lastname: '',
        email: '',
        //address: '',
        //city: '',
        //state: '',
        //zip: '',
        phone: '',
        //company: '',
        //title: '',
        message: ''
        //isSubscribed: false,
      });
      return Ember.RSVP.hash({
        isSubmitted: this.get('isSubmitted'),
        prospect: Prospect
      });
    },

    afterModel: function afterModel(transition) {
      Ember.$('*').animate({ scrollTop: 0 }, 500);
      this._super(transition);
    },
    actions: {
      submitContact: function submitContact() {
        function failure(reason) {
          this.set('isError', true);
          this.set('errorReason', reason);
        }
        function completedMessage() {
          self.controller.set('model.isSubmitted', true);
          this.set('isError', false);
          this.set('errorReason', null);
        }

        // do something... call the api, send an email...
        var Prospect = this.controller.get('model.prospect');
        Prospect.save().then(completedMessage).catch(failure);
      }
    }
  });
});