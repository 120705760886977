define('slarson/adapters/application', ['exports', 'ember-data', 'slarson/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$String = Ember.String,
      pluralize = _Ember$String.pluralize,
      underscore = _Ember$String.underscore;
  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _environment.default.host,
    namespace: _environment.default.namespace,
    pathForType: function pathForType(type) {
      return pluralize(underscore(type));
    }
  });
});