define('slarson/routes/news/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            this.transitionTo('news.2020.stimulus-rebate');
        }
    });
});